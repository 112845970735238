const navbarReducer = (state = { data:null }, action) => {
    switch (action.type) {
        case "NAVBAR_VANISH":
            return { ...state, data: action?.payload };
      default:
        return state;
    }
  };
  
  export default navbarReducer;
  