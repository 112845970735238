export const setInitialDimensions = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "SET_INITIAL_DIMENSIONS", payload });
  } catch (error) {
    console.log(error);
  }
};

export const setCurrentDimensions = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "SET_CURRENT_DIMENSIONS", payload });
  } catch (error) {
    console.log(error);
  }
};
