import React, { useEffect, useState, useRef } from "react";
import "./Subnavbar.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";

function Subnavbar(props) {
  let content;
  const [isFocused, setIsFocused] = useState(false);
  const [showClear, setShowClear] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const inputRef = useRef(null);
  useEffect(() => {
    setShowClear(searchInput.trim() !== "");
  }, [searchInput]);

  const inputStyle = {
    border: isFocused ? "none" : "none",
    padding: `0px 35px`,
    marginLeft: `${props.deviceType === "Phone" || "Tablet" ? "7px" : ""}`,
    borderRadius: "4px",
    zIndex: "0",
    outline: "none",
    transition: "border-color 0.3s ease",
    boxShadow: isFocused ? "none" : "none",
  };

  function handleMenuClick() {
    if (props.deviceType !== "Desktop") {
      props.setPtoggle(!props.ptoggle);
      props.setStoggle(!props.setStoggle);
      props.setTrigger(!props.onClose);
    } else if (props.deviceType === "Desktop") {
      props.setHovered(!props.onClose);
    }
  }

  switch (props.item) {
    case "About":
      content = (
        <div
          className={`popup-1 leading-[1.8] ${
            props.vanish ? "pt-12 ml-[17vw]" : "ml-[13vw]"
          } ${props.deviceType === "Phone" || "Tablet" ? "pt-[6vh]" : ""}`}
        >
          <section className="popup-sections pt-6">
            <div onClick={handleMenuClick}>
              <NavLink to="/about" className="subnavlink">
                About Us
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/about/our-brand" className="subnavlink">
                Our Brand
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/about/our-vision" className="subnavlink">
                Otiva Vision
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/about/ceo-message" className="subnavlink">
                CEO's Message
              </NavLink>
            </div>
          </section>

          <section className="popup-sections">
            <div>
              <span className="subnavheading">Corporate Profile</span>
              <div>
                <ul>
                  <li onClick={handleMenuClick}>
                    <NavLink
                      to="/about/corporate-profile/board-of-directors"
                      className="subnavlink"
                    >
                      Board of Directors
                    </NavLink>
                  </li>
                  <li onClick={handleMenuClick}>
                    <NavLink
                      to="/about/corporate-profile/management"
                      className="subnavlink"
                    >
                      Management
                    </NavLink>
                  </li>
                  <li onClick={handleMenuClick}>
                    <NavLink
                      to="/about/corporate-profile/facts-and-figures"
                      className="subnavlink"
                    >
                      Facts & Figures
                    </NavLink>
                  </li>
                  <li onClick={handleMenuClick}>
                    <NavLink
                      to="/about/corporate-profile/otiva-at-a-glance"
                      className="subnavlink"
                    >
                      Otiva at a Glance
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="popup-sections">
            <div>
              <span className="subnavheading">Our Compliance Culture</span>
              <div>
                <ul>
                  <li onClick={handleMenuClick}>
                    <NavLink
                      to="/about/our-compliance-culture/"
                      className="subnavlink"
                    >
                      Our Compliance Culture
                    </NavLink>
                  </li>
                  <li onClick={handleMenuClick}>
                    <NavLink
                      to="/about/our-compliance-culture/our-compiance-process"
                      className="subnavlink"
                    >
                      Our Compliance Process
                    </NavLink>
                  </li>
                  <li onClick={handleMenuClick}>
                    <NavLink
                      to="/about/our-compliance-culture/our-codeof-ethics"
                      className="subnavlink"
                    >
                      Our Code of Ethics
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="popup-sections">
            <div>
              <span className="subnavheading">Locations</span>
              <div>
                <ul>
                  <li onClick={handleMenuClick}>
                    <NavLink
                      to="/about/locations/otiva-worldwide/"
                      className="subnavlink"
                    >
                      Otiva Worldwide
                    </NavLink>
                  </li>
                  <li onClick={handleMenuClick}>
                    <NavLink
                      to="/about/locations/sales-office"
                      className="subnavlink"
                    >
                      Sales Office
                    </NavLink>
                  </li>
                  <li onClick={handleMenuClick}>
                    <NavLink to="/about/locations/" className="subnavlink">
                      Locations
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="about-5 popup-sections pt-6">
            <div onClick={handleMenuClick}>
              <NavLink to="/about/our-quality-standard" className="subnavlink">
                Our Quality Standard
              </NavLink>
            </div>
          </section>
        </div>
      );
      break;
    case "Products":
      content = (
        <div
          className={`popup-1 products leading-[1.8] ${
            props.vanish ? "pt-12 ml-[17vw]" : "ml-[13vw]"
          }`}
        >
          <section className="popup-sections">
            <div onClick={handleMenuClick}>
              <NavLink to="/products" className="subnavlink">
                Overview
              </NavLink>
            </div>
          </section>
          <section className="popup-sections">
            <div onClick={handleMenuClick}>
              <NavLink to="/products/polymers" className="subnavlink">
                Polymers
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/products/fiber" className="subnavlink">
                Fiber
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/products/plastics" className="subnavlink">
                Plastic
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink
                to="/products/electricals-and-electronics"
                className="subnavlink"
              >
                Electricals
              </NavLink>
            </div>
          </section>
          <section className="popup-sections">
            <div onClick={handleMenuClick}>
              <NavLink to="/products/ebusiness" className="subnavlink">
                e-Business
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink
                to="/products/terms-and-conditions-sale"
                className="subnavlink"
              >
                Terms and Conditions of Sale
              </NavLink>
            </div>
          </section>
        </div>
      );
      break;
    case "Industries":
      content = (
        <div
          className={`popup-1 industries leading-[1.8] ${
            props.vanish ? "pt-12 ml-[17vw]" : "ml-[13vw]"
          }`}
        >
          <section className="popup-sections">
            <div onClick={handleMenuClick}>
              <NavLink to="/industries" className="subnavlink">
                Overview
              </NavLink>
            </div>
          </section>
          <section className="popup-sections">
            <div onClick={handleMenuClick}>
              <NavLink to="/industries/automotive" className="subnavlink">
                Automotive
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink
                to="/industries/building-and-constructions"
                className="subnavlink"
              >
                Building & Construction
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/industries/consumer" className="subnavlink">
                Consumer
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink
                to="/industries/electrical-and-electronics"
                className="subnavlink"
              >
                Electrical & Electronics
              </NavLink>
            </div>
          </section>
        </div>
      );
      break;
    case "Sustainability":
      content = (
        <div
          className={`popup-1 leading-[1.8] ${
            props.vanish ? "pt-12 ml-[17vw]" : "ml-[13vw]"
          } ${props.deviceType === "Phone" || "Tablet" ? "pt-[6vh]" : ""} `}
        >
          <section className="popup-sections pt-6">
            <div onClick={handleMenuClick}>
              <NavLink to="/sustainability" className="subnavlink">
                Overview
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink
                to="/sustainability/circular-economy"
                className="subnavlink"
              >
                Circular Economy
              </NavLink>
            </div>
            <div className="w-[320px]" onClick={handleMenuClick}>
              <NavLink
                to="/sustainability/climate-energy-resource-efficiency"
                className="subnavlink"
              >
                Climate, Energy & Resource Efficiency
              </NavLink>
            </div>
          </section>
          <section className="popup-sections">
            <span className="subnavheading">Product Stewardship</span>
            <div className=" w-[250px]" onClick={handleMenuClick}>
              <NavLink
                to="/sustainability/product-stewardship"
                className="subnavlink"
              >
                Commitment & Approach
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink
                to="/sustainablity/product-stewardship/initiatives-and-engagement"
                className="subnavlink"
              >
                Initiatives & Engagement
              </NavLink>
            </div>
            <div className="w-[260px]" onClick={handleMenuClick}>
              <NavLink
                to="/sustainability/product-stewardship/product-regulatory-documents"
                className="subnavlink"
              >
                Product Regulatory Documents
              </NavLink>
            </div>
          </section>
          <section className="popup-sections">
            <span className="subnavheading">Governance & Reporting</span>
            <div onClick={handleMenuClick}>
              <NavLink
                to="/sustainability/governance-and-reporting"
                className="subnavlink"
              >
                Governance
              </NavLink>
            </div>
            <div className="w-[220px]" onClick={handleMenuClick}>
              <NavLink
                to="/sustainability/governance-and-reporting/government-engagement"
                className="subnavlink"
              >
                Government Engagement
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/reporting" className="subnavlink">
                Corporate Reporting
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink
                to="/sustainability/governance-and-reporting/ungc-principles"
                className="subnavlink"
              >
                UNGC Principles
              </NavLink>
            </div>
          </section>
          <section className="popup-sections pt-6">
            <div className="w-[200px]" onClick={handleMenuClick}>
              <NavLink
                to="/sustainablity/people-and-collaboration"
                className="subnavlink"
              >
                People & Collaboration
              </NavLink>
            </div>
          </section>
        </div>
      );
      break;
    case "Careers":
      content = (
        <div
          className={`popup-1 careers leading-[1.8] ${
            props.vanish ? "pt-12 ml-[17vw]" : "ml-[13vw]"
          }`}
        >
          <section className="popup-sections">
            <div onClick={handleMenuClick}>
              <NavLink to="/careers" className="subnavlink">
                Careers at Otiva
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/careers/why-work-with-us" className="subnavlink">
                Why work with us?
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink
                to="/careers/benefits-that-matter"
                className="subnavlink"
              >
                Benefits that matter
              </NavLink>
            </div>
          </section>
          <section className="popup-sections">
            <div onClick={handleMenuClick}>
              <NavLink to="/careers/careers-worldwide" className="subnavlink">
                Careers Worldwide
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/careers/talent-community" className="subnavlink">
                Talent Community
              </NavLink>
            </div>
          </section>
        </div>
      );
      break;
    case "Investors":
      content = (
        <div
          className={`popup-1 leading-[1.8] ${
            props.vanish ? "pt-12 ml-[17vw]" : "ml-[13vw]"
          } ${props.deviceType === "Phone" || "Tablet" ? "pt-[6vh]" : ""}`}
        >
          <section className="popup-sections pt-6">
            <div onClick={handleMenuClick}>
              <NavLink to="/investors" className="subnavlink">
                Overview
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink
                to="/investors/corporate-governance"
                className="subnavlink"
              >
                Corporate Governance
              </NavLink>
            </div>
          </section>
          <section className="popup-sections">
            <span className="subnavheading">
              Performance and Financial Highlights
            </span>
            <div onClick={handleMenuClick}>
              <NavLink
                to="/investors/performance-financial-highlights"
                className="subnavlink"
              >
                Performance Financial Highlights
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink
                to="/investors/performance-financial-highlights/annual-report"
                className="subnavlink"
              >
                Annual Reports
              </NavLink>
            </div>
          </section>
          <section className="popup-sections">
            <span className="subnavheading">FAQ and Contact</span>
            <div onClick={handleMenuClick}>
              <NavLink to="/investors/faq-contact" className="subnavlink">
                Contact Details
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/investors/faq-contact/faq" className="subnavlink">
                FAQs
              </NavLink>
            </div>
          </section>
        </div>
      );
      break;
    case "News and Media":
      content = (
        <div
          className={`popup-1 newsandmedia leading-[1.8] ${
            props.vanish ? "pt-12 ml-[17vw]" : "ml-[13vw]"
          }`}
        >
          <section className="popup-sections">
            <div onClick={handleMenuClick}>
              <NavLink to="/newandmedia" className="subnavlink">
                Otiva News & Media
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/newandmedia/news" className="subnavlink">
                Latest News
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/newandmedia/reports" className="subnavlink">
                Reports
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/newandmedia/stories" className="subnavlink">
                Stories
              </NavLink>
            </div>
          </section>
          <section className="popup-sections">
            <div onClick={handleMenuClick}>
              <NavLink
                to="/newandmedia/media-centre-photos"
                className="subnavlink"
              >
                Photos
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink
                to="/newandmedia/media-centre-videos"
                className="subnavlink"
              >
                Otiva Videos
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink
                to="/newandmedia/media-centre-logos"
                className="subnavlink"
              >
                Logos
              </NavLink>
            </div>
          </section>
          <section className="popup-sections">
            <div onClick={handleMenuClick}>
              <NavLink to="/newandmedia/socialmedia" className="subnavlink">
                Social Media
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/newandmedia/otiva-events" className="subnavlink">
                Events
              </NavLink>
            </div>
          </section>
        </div>
      );
      break;
    case "search":
      content = (
        <div
          className={`popup-1 leading-[1.8] ${
            props.vanish ? "pt-12 ml-[17vw]" : "ml-[13vw]"
          } ${
            props.deviceType !== "Desktop"
              ? `pt-[15vh] ml-[8vw] z-50`
              : ""
          }`}
        >
          <section className="popup-sections">
            <div>
              <form action="/search" method="post" className="search-form">
                <FontAwesomeIcon
                  icon={faSearch}
                  className={`text-gray-400 text-lg ${
                    props.deviceType === "Phone" || "Tablet" ? "ml-1" : ""
                  }`}
                />
                <input
                  type="text"
                  className="search-box -mt-10"
                  placeholder="Search Otivaindustries.com"
                  style={inputStyle}
                  autoFocus
                  autoComplete="off"
                  value={searchInput}
                  name="searchInput"
                  onChange={(e) => setSearchInput(e.target.value)}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  ref={inputRef}
                />
              </form>
            </div>
            <button
              className={`clear-input ${props.vanish ? "pt-6" : ""}`}
              hidden={!showClear}
              onClick={() => {
                setSearchInput("");
                inputRef.current.focus();
              }}
            >
              <FontAwesomeIcon icon={faXmark} className="clearicon" />
            </button>
          </section>
        </div>
      );
      break;
    case "contact":
      content = (
        <div
          className={`widget-popup leading-[1.8] ${
            props.vanish ? "ml-[69vw] -mt-[53vh]" : "ml-[60vw] -mt-[55vh]"
          } ${props.stogggle ? "" : ""}`}
        >
          <section className="">
            <div onClick={handleMenuClick}>
              <NavLink to="/contact" className="subnavlink">
                Contact Us
              </NavLink>
            </div>
            <div onClick={handleMenuClick}>
              <NavLink to="/about/locations" className="subnavlink">
                Locations
              </NavLink>
            </div>
          </section>
        </div>
      );
      break;
    case "language":
      content = (
        <div
          className={`widget-popup leading-[1.8] ${
            props.vanish ? "ml-[72vw] -mt-[48vh]" : "ml-[63vw] -mt-[50vh]"
          }`}
        >
          <section className="popup-sections">
            <div>
              <NavLink to="/language" className="subnavlink">
                English
              </NavLink>
            </div>
            <div>
              <NavLink to="/language" className="subnavlink">
                French
              </NavLink>
            </div>
            <div>
              <NavLink to="/language" className="subnavlink">
                Spanish
              </NavLink>
            </div>
          </section>
        </div>
      );
      break;
    case "cart":
      content = (
        <div
          className={`widget-popup leading-[1.8] ${
            props.vanish ? "ml-[72vw] -mt-[53vh]" : "ml-[63vw] -mt-[55vh]"
          }`}
        >
          <section className="popup-sections">
            <div>
              <a
                href="https://ebusiness.otivaindustries.com/"
                target="_blank"
                rel="noreferrer"
                className="subnavlink"
              >
                E-Business Portal
              </a>
            </div>
            <div>
              <a
                href="https://supplier.otivaindustries.com/"
                target="_blank"
                rel="noreferrer"
                className="subnavlink"
              >
                Supplier Portal
              </a>
            </div>
          </section>
        </div>
      );
      break;
    case "account":
      content = (
        <div
          className={`widget-popup leading-[1.8] ${
            props.vanish ? "ml-[77vw] -mt-[53vh]" : "ml-[67vw] -mt-[55vh]"
          }`}
        >
          <section className="popup-sections">
            <div className="subnavlink cursor-pointer">Login</div>
            <div className="subnavlink cursor-pointer">Register</div>
          </section>
        </div>
      );
      break;

    default:
      content = null;
      break;
  }

  return props.trigger ? (
    <div
      className={`popup  ${
        props.vanish ? "top-[8.6vh] left-0" : "top-[17vh] left-0"
      } ${
        props.deviceType === "Phone"
          ? `${props.stoggle ? "slide-enter" : "slide-exit"}`
          : ""
      } ${
        props.deviceType === "Tablet"
          ? `${props.stoggle ? "slide-enter" : "slide-exit"}`
          : ""
      }`}
    >
      {content}
    </div>
  ) : (
    ""
  );
}

export default Subnavbar;
