import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  setInitialDimensions,
} from "./actions/dimensions";

import "./App.css";
import DefaultLayout from "./Layouts/DefaultLayout";
import Landing from "./pages/landing/Landing.jsx";
import ScrolltoTop from "./components/ScrolltoTop.jsx";
import NotFound from "./pages/NotFound/NotFound.jsx";
import Blur from "./components/navbar/Blur.jsx";
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.screen.orientation.addEventListener("change", function (e) {
      dispatch(setInitialDimensions(window.screen.orientation.type));
    });
  });

  const dimensions = useSelector((state) => state.dimensionsReducer.data);

  return (
    <div className="App">
      {dimensions === "landscape-primary" ? (
        <Blur trigger={true} vanish={false} rotate={true} />
      ) : (
        <Router>
          <ScrolltoTop />
          <Routes>
            <Route element={<DefaultLayout />}>
              <Route path="/" element={<Landing />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
