import React from "react";
import "./Blur.css";

import landscape from "../../assets/images/landscape.jpg"

function Blur(props) {
  return props.trigger ? (
    <div className={`${props.rotate?`rotate-blur`:`blur`} ${props.vanish ? "h-[90vh]" : "h-[25vh]"}`}>
      {props.rotate ? (
        <>
          <div style={{ zIndex: "100" }}>
            <img src={landscape} alt="landscape mode warning" style={{height:"310px",width:"60vw"}}/>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  ) : (
    ""
  );
}

export default Blur;
